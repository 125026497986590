<template>
    <div class="box">
        <h2>
            当前账户余额：<span class="balance">￥{{ balanceInfo.balance }}</span> <span class="hints">（正数为预付款，负数为已发货未结算金额）</span>
            当前账户积分：<span class="balance">{{ balanceInfo.point }}</span>
        </h2>
        <div class="form-row">
            <div class="form-item" style="margin-right: 30px">
                <span>结算方式</span>
                <el-select v-model="pay_name" size="small" class="get-method" placeholder="所有">
                    <el-option v-for="item in options" :key="item.pay_name" :label="item.label" :value="item.pay_name"> </el-option>
                </el-select>
            </div>
            <div class="form-item">
                <span>时间范围</span>
                <el-date-picker v-model="timeArea" size="small" type="daterange" align="right" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions"> </el-date-picker>

                <el-button class="search-btn" @click="search()"> <img src="~img/alibaba/search.png" />搜索</el-button>
            </div>
        </div>
        <div class="table">
            <el-table :data="accountList" class="top1">
                <el-table-column label="结算时间" align="center">
                    <template slot-scope="scope">
                        {{ $util.timeStampTurnTime(scope.row.create_time) }}
                    </template>
                </el-table-column>
                <el-table-column prop="pay_name" label="结算方式" align="center"> </el-table-column>
                <el-table-column prop="price" label="结算金额" align="center"> </el-table-column>
                <el-table-column prop="content" label="备注" width="300" align="center"> </el-table-column>
            </el-table>
        </div>
        <!-- <el-pagination :page-size="100" layout="prev, pager, next, jumper" :total="1000"> </el-pagination> -->
        <div class="jump">
            <el-pagination class="jump-count" :page-size="pageSize" @current-change="handlePageChange" :current-page.sync="currentPage" layout="prev, pager, next, jumper" ref="pagination" :total="total"> </el-pagination>
        </div>
    </div>
</template>

<script>
import { balance, withdrawConfig, balanceDetail } from "@/api/member/account"
export default {
    name: "account",
    components: {},
    data: () => {
        return {
            list: [],
            loading: true,
            yes: true,
            balanceInfo: {},
            pay_name: "全部",
            timeArea: [],
            value2: 1,
            // options: [
            //     { label: "全部", value: "all" },
            //     { label: "微信", value: "wx" },
            //     { label: "支付宝", value: "alipay" }
            // ],
            options: {},
            pickerOptions: [],
            accountList: [],
            currentPage: 1,
            total: 0,
            pageSize: 10,
            beginTime: "",
            endTime: "",
            message: ""
        }
    },
    created() {
        this.getAccount(), this.getAccountList()
    },
    mounted() {
        let self = this
        setTimeout(function () {
            self.yes = false
        }, 300)
    },
    methods: {
        //获取余额信息
        getAccount() {
            balance({ account_type: "balance,balance_money,point" })
                .then((res) => {
                    if (res.code == 0 && res.data) {
                        this.balanceInfo = res.data
                        const price = (parseFloat(this.balanceInfo.balance) + parseFloat(this.balanceInfo.balance_money)).toFixed(2)
                        let priceSplit = price.split(".")
                        this.integer = priceSplit[0]
                        this.decimal = priceSplit[1]
                    }
                    this.loading = false
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err.message)
                })
        },
        //获取余额明细
        getAccountList() {
            balanceDetail({
                page_size: this.page_size,
                page: this.currentPage,
                // account_type: "balance",
                // message:this.message,
                begin_time: this.beginTime,
                end_time: this.endTime,
                pay_name: this.pay_name
            })
                .then((res) => {
                    if (res.code == 0 && res.data) {
                        this.accountList = res.data.list
                        // console.log(res.name)  // 支付结算方式
                        let options = {}
                        options[0] = { label: "全部", pay_name: "全部" }
                        for (let i in res.name) {
                            // options.push(res.name[i].name)
                            options[i + 1] = { label: res.name[i].name, pay_name: res.name[i].name }
                        }
                        // console.log(options)
                        this.options = options
                        // console.log(this.accountList)
                        this.total = res.data.count
                        this.accountList.forEach((item) => {
                            item.time = this.$util.timeStampTurnTime(item.create_time)
                        })
                    }
                })
                .catch((err) => {
                    this.$message.error(err.message)
                })
        },
        search() {
            console.log(this.timeArea.length)
            console.log("账户明细")
            if (this.timeArea.length == 0) {
                this.beginTime = "946656000"
                this.endTime = "4070880000"
            } else {
                this.beginTime = this.timeArea[0].getTime() / 1000
                this.endTime = this.timeArea[1].getTime() / 1000
            }
            this.loading = true
            this.getAccountList()
        },
        handlePageSizeChange(num) {
            // this.account.page_size = num
            this.getAccountList()
        },
        handleCurrentPageChange(page) {
            this.account.page = page

            this.getAccountList()
        },
        applyWithdrawal() {
            this.$router.push("/member/apply_withdrawal")
        },
        rechargeList() {
            this.$router.push("/member/recharge_list")
        },
        handlePageSizeChange(size) {
            this.pageSize = size
            this.refresh()
        },
        handlePageChange() {
            this.getAccountList()
        }
    }
}
</script>
<style lang="scss" scoped>
.box {
    width: 100%;
    position: relative;

    h2 {
        font-size: 24px;
        color: $ns-text-color-black;
        padding-bottom: 5px;
        border-bottom: 1px solid #d9d9d9;

        .balance {
            margin-left: 10px;
            color: red;
            vertical-align: -2px;
        }

        .hints {
            margin-left: 20px;
            font-size: 12px;
            color: $ns-text-color-gray;
            font-weight: 400;
            vertical-align: -1px;
        }
    }

    .form-row {
        display: flex;
        align-items: center;
        margin-top: 30px;

        .form-item {
            display: flex;
            align-items: center;
            color: $ns-text-color-black;

            span {
                margin-right: 10px;
            }

            .search-btn {
                height: 32px;
                width: 100px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: $ns-font-size-base;
                color: #ffffff;
                background-color: $base-color;
                margin-left: 10px;

                img {
                    width: 14px;
                    height: 14px;
                    margin-right: 5px;
                }
            }
        }
    }

    .table {
        margin-top: 30px;
    }

    .el-pagination {
        margin-top: 30px;
    }
}
.top1 {
    width: 1240px !important;
}
</style>
